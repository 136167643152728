// Customizable Area Start
import React from 'react';
export {
	CometChatConversationList,
	CometChatConversationListItem,
	CometChatConversationListWithMessages,
} from "./Chats/";

export {
	CometChatSmartReplyPreview,
	CometChatStickerKeyboard,
	CometChatMessageReactions,
	CometChatCreatePoll,
	CometChatReceiverDocumentBubble,
	CometChatReceiverPollMessageBubble,
	CometChatReceiverStickerMessageBubble,
	CometChatReceiverWhiteboardBubble,
	CometChatSenderDocumentBubble,
	CometChatSenderPollMessageBubble,
	CometChatSenderStickerBubble,
	CometChatSenderWhiteboardBubble,
	CometChatActionMessageBubble,
	CometChatDeleteMessageBubble,
	CometChatEmojiKeyboard,
	CometChatImageViewer,
	CometChatLinkPreview,
	CometChatLiveReactions,
	CometChatMessageActions,
	CometChatMessageComposer,
	CometChatMessageHeader,
	CometChatMessageList,
	CometChatMessages,
	CometChatMessageThread,
	CometChatReadReceipt,
	CometChatReceiverAudioMessageBubble,
	CometChatReceiverDirectCallBubble,
	CometChatReceiverFileMessageBubble,
	CometChatReceiverImageMessageBubble,
	CometChatReceiverTextMessageBubble,
	CometChatReceiverVideoMessageBubble,
	CometChatSenderAudioMessageBubble,
	CometChatSenderDirectCallBubble,
	CometChatSenderFileMessageBubble,
	CometChatSenderImageMessageBubble,
	CometChatSenderTextMessageBubble,
	CometChatSenderVideoMessageBubble,
	CometChatThreadedMessageReplyCount,
} from "./Messages/";

export {
	CometChatAvatar,
	CometChatBackdrop,
	CometChatBadgeCount,
	CometChatUserPresence,
	CometChatToastNotification,
} from "./Shared/";

export { CometChatUI } from "./CometChatUI/";

export {
	CometChatUserDetails,
	CometChatUserList,
	CometChatUserListItem,
	CometChatUserListWithMessages,
} from "./Users/";

export const dateShow = (date) => {
	if(date){
		const options = { timeZone: 'Asia/Kolkata', day: 'numeric', month: 'short', year: 'numeric' }
		const formattedDate = new Intl.DateTimeFormat('en-IN', options).format(new Date(date * 1000));
		return <div>{formattedDate}</div>;
	}
};

export const dateTimeShow = (date) => {
	if(date) {
		const options = { timeZone: 'Asia/Kolkata', day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
		const formattedDate = new Intl.DateTimeFormat('en-IN', options).format(new Date(date * 1000));
		return <div>{formattedDate}</div>;
	}
};
// Customizable Area End
